import axios from "axios";

export const listComments = async (teamId: string, bookmarkId: string) => await axios.get(`/bookmarks/${teamId}/${bookmarkId}/comments`).then((res) => res.data).catch((err) => err);

interface PostComment {
  bookmarkId: string;
  comment: string;
}

export const postComment = async (teamId: string, payload: PostComment) => await axios.post(`/bookmarks/${teamId}/${payload.bookmarkId}/comments`, payload).then((res) => res.data).catch((err) => err);
