import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DataContext } from "../../context/Data.context";
import { AlertContext, copyToClipboard, Loading, Separator } from "@moreirapontocom/npmhelpers";
import { useFormik } from "formik";
import { getTeam, inviteTeamMember, removeTeamMember, updateTeam, updateTeamMemberRole } from "../../services/teams.service";
import * as yup from "yup";

const TeamSettings = () => {
  const params: any = useParams();
  const { data, setData }: any = useContext(DataContext);
  const [teamId, setTeamId] = useState(null as any);
  const {setAlert} = useContext(AlertContext);

  useEffect(() => {
    setTeamId(params.teamId);
    _getTeamSettings(params.teamId);
  }, []);

  const [currentRole, setCurrentRole] = useState(null as any);
  const [loadingTeamSettings, setLoadingTeamSettings] = useState(true);
  const [team, setTeam] = useState({} as any);

  const teamInitialValues: any = {
    name: team.name || "",
  };

  const _getTeamSettings = async (teamId: string) => {
    setLoadingTeamSettings(true);
    const response: any = await getTeam(teamId).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      response.team.members.sort((a: any, b: any) => a.role.localeCompare(b.role));
      setTeam(response.team);
      setCurrentRole(response.currentRole);
      setData({ ...data, currentTeam: response.team });
      formUpdateTeam.setValues(response.team);
      setLoadingTeamSettings(false);
      return;
    }

    setLoadingTeamSettings(false);
  };

  const formUpdateTeam: any = useFormik({
    initialValues: teamInitialValues,
    validateOnMount: true,
    validationSchema: yup.object({
      name: yup.string().required("Team Name is required")
    }),
    onSubmit: async (values) => {
      formUpdateTeam.setSubmitting(true);

      const response: any = await updateTeam(params.teamId || teamId, values).then((res: any) => res).catch((err: any) => err);

      if (response.message === "OK") {
        setTeam({...team, name: values.name});
        setData({
          ...data,
          currentTeam: { ...data.currentTeam, name: values.name, },
        });
        setAlert({type: "success", message: "Team updated"});
      }

      formUpdateTeam.setSubmitting(false);
    }
  });

  const [loadingTeamMemberEmail, setLoadingTeamMemberEmail] = useState(null);

  const [loadingUpdateTeamMemberRole, setLoadingUpdateTeamMemberRole] = useState(false);
  const _onChangeMemberRole = async (member: any, newRole: string) => {
    setLoadingUpdateTeamMemberRole(true);
    setLoadingTeamMemberEmail(member.email);
    const _payload: any = {
      memberEmail: member.email, // when invited, the new member doesn't have an id yet
      newRole,
    };
    const response: any = await updateTeamMemberRole(teamId, _payload).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      const _team: any = team;
      _team.members = team.members.map((item: any) => item.email === member.email ? { ...item, role: newRole } : item);
      setTeam(_team);
      setLoadingUpdateTeamMemberRole(false);
      setAlert({type: "success", message: "Role updated"});
      setLoadingTeamMemberEmail(null);
      return;
    }

    setLoadingUpdateTeamMemberRole(false);
    setLoadingTeamMemberEmail(null);
    console.log("Error updating team member role");
  };

  const newMemberDefaultValues: any = {
    email: "",
    role: "",
  };

  const formNewMember: any = useFormik({
    initialValues: newMemberDefaultValues,
    validateOnMount: true,
    validationSchema: yup.object({
      email: yup.string().email("Invalid email").required("Email is required"),
      role: yup.string().required("Role is required").oneOf(["admin", "member", "viewer"], "Invalid role"),
    }),
    onSubmit: async (values) => {
      // Check if member is already in the team
      const memberExists: boolean = team.members.some((member: any) => member.email === values.email);
      if (memberExists) {
        setAlert({type: "warning", message: "Member already in the team"});
        return;
      }

      formNewMember.setSubmitting(true);
      const _payload: any = {
        memberEmail: values.email,
        role: values.role,
      };

      const response: any = await inviteTeamMember(teamId, _payload).then((res: any) => res).catch((err: any) => err);
      if (response.message === "OK") {
        setAlert({type: "success", message: "Member invited"});
        formNewMember.resetForm();

        const _team: any = team;
        _team.members.push({email: values.email, role: values.role, status: "invited"});
        _team.members.sort((a: any, b: any) => a.role.localeCompare(b.role));

        const localData: any = localStorage.getItem("data");
        if (localData) {
          const _data: any = JSON.parse(localData);
          _data.currentTeam.members = _team.members;
          localStorage.setItem("data", JSON.stringify(_data));
        }

        setTeam({...team, members: _team.members});
        setData({
          ...data,
          currentTeam: { ...data.currentTeam, members: _team.members, membersCount: _team.members.length, },
        });

        formNewMember.setSubmitting(false);
        return;
      }

      formNewMember.setSubmitting(false);
      setAlert({type: "danger", message: "Error inviting member"});
      console.log("Error inviting member", response);
    }
  });

  const [loadingRemoveMember, setLoadingRemoveMember] = useState(false);
  const _removeMember = async (member: any) => {
    setLoadingRemoveMember(true);
    setLoadingTeamMemberEmail(member.email);
    const response: any = await removeTeamMember(teamId, {memberEmail: member.email}).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      const _team: any = team;
      const _members: any = _team.members.filter((item: any) => item.email !== member.email);
      _team.members = _members;

      const localData: any = localStorage.getItem("data");
      if (localData) {
        const _data: any = JSON.parse(localData);
        _data.currentTeam.members = _members;
        localStorage.setItem("data", JSON.stringify(_data));
      }

      setTeam({...team, members: _team.members});
      setData({
        ...data,
        currentTeam: { ...data.currentTeam, members: _team.members, membersCount: _team.members.length, },
      });

      setAlert({type: "success", message: "Member removed"});
      setLoadingRemoveMember(false);
      setLoadingTeamMemberEmail(null);
      return;
    }

    setLoadingRemoveMember(false);
    setLoadingTeamMemberEmail(null);
    setAlert({type: "danger", message: "Error removing member"});
    console.log("Error removing member", response);
  };

  return <>
    <div className="container">

      <Loading loading={loadingTeamSettings} />

      {!loadingTeamSettings && <>

        {["member", "viewer"].includes(currentRole) && <>
          <div className="alert alert-danger">
            <i className="fas fa-exclamation-triangle me-2"></i> Hey <strong>stranger</strong>! You don't have permission to access this page.
          </div>
        </>}

        {currentRole === "admin" && <>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="team-members-tab"
                data-bs-toggle="tab"
                data-bs-target="#team-members-tab-pane"
                type="button"
                role="tab"
                aria-controls="team-members-tab-pane"
                aria-selected="false">Members</button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="team-settings-tab"
                data-bs-toggle="tab"
                data-bs-target="#team-settings-tab-pane"
                type="button"
                role="tab"
                aria-controls="team-settings-tab-pane"
                aria-selected="true">Team Settings</button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="content-management-tab"
                data-bs-toggle="tab"
                data-bs-target="#content-management-tab-pane"
                type="button"
                role="tab"
                aria-controls="content-management-tab-pane"
                aria-selected="true">Content Management</button>
            </li>
          </ul>

          <Separator size={30} />

          <div className="tab-content" id="myTabContent">
            <div className="tab-pane" id="team-settings-tab-pane" role="tabpanel" aria-labelledby="team-settings-tab">

              <small className="text-muted">
                Update basic information about your team.
              </small>

              <Loading loading={loadingTeamSettings} />

              {!loadingTeamSettings && <>
                <Separator size={20} />

                <form onSubmit={formUpdateTeam.handleSubmit}>
                  <div className="form-group mb-3">
                    <label>Team Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      maxLength={46}
                      autoComplete="off"
                      disabled={formUpdateTeam.isSubmitting}
                      value={formUpdateTeam.values.name}
                      onChange={formUpdateTeam.handleChange} />
                  </div>

                  <button
                    type="submit"
                    disabled={!formUpdateTeam.isValid || formUpdateTeam.isSubmitting}
                    className="btn btn-primary">
                      <Loading loading={formUpdateTeam.isSubmitting} parent="inline" color="text-white" />
                      {!formUpdateTeam.isSubmitting && <i className="fas fa-check me-2"></i>} Update Team
                  </button>

                  <Link
                    type="link"
                    to={`/teams/${teamId}`}
                    className="btn btn-outline-secondary ms-3 float-end">
                      <i className="fa fa-ban me-2"></i> Cancel
                  </Link>
                </form>
              </>}

            </div>
            <div className="tab-pane show active" id="team-members-tab-pane" role="tabpanel" aria-labelledby="team-members-tab">

              <div className="card shadow-0 border-0 bg-light">
                <div className="card-body">

                  <strong>Invite members via email</strong>

                  <Separator size={10} />

                  <form onSubmit={formNewMember.handleSubmit}>
                    <div className="row align-items-end">
                      <div className="col">
                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            disabled={formNewMember.isSubmitting}
                            value={formNewMember.values.email}
                            onChange={formNewMember.handleChange}
                            autoComplete="off"
                            className="form-control text-lowercase"
                            placeholder="Member email" />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <select
                            name="role"
                            disabled={formNewMember.isSubmitting}
                            value={formNewMember.values.role}
                            onChange={formNewMember.handleChange}
                            className="form-control">
                              <option value="">Role</option>
                              <option value="admin">Admin</option>
                              <option value="member">Member</option>
                              <option value="viewer">Viewer</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-2 text-end">
                        <div className="d-grid">
                          <button
                            type="submit"
                            disabled={!formNewMember.isValid || formNewMember.isSubmitting}
                            className="btn btn-outline-primary">
                              <Loading loading={formNewMember.isSubmitting} parent="inline" color="text-primary" />
                              {!formNewMember.isSubmitting && <i className="fas fa-envelope me-2"></i>} Send Invite
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                  <Separator size={10} />

                  <small className="text-muted">
                    <ul>
                      <li><strong>Admin:</strong> can manage team, bookmarks, tags, comments and members</li>
                      <li><strong>Member:</strong> can create bookmarks, tags and comments</li>
                      <li><strong>Viewer:</strong> can view bookmarks and comments</li>
                    </ul>
                  </small>

                  <Separator size={10} />

                  <strong>Or invite members via link</strong>

                  <Separator size={10} />

                  <div className="row">
                    <div className="col-6">

                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          onFocus={(e: any) => e.target.select()}
                          value={`${window.location.origin}/join/${teamId}`}
                          readOnly
                        />
                        <button
                          type="button"
                          onClick={(e: any) => {
                            copyToClipboard(`${window.location.origin}/join/${teamId}`)
                            setAlert({type: "success", message: "Link copied to clipboard"});
                          }}
                          className="btn btn-outline-primary">
                            <i className="fas fa-copy"></i>
                        </button>
                      </div>

                    </div>
                  </div>

                  <Separator size={10} />

                  <small className="text-muted">
                    All new members will have the role of <strong>Viewer</strong> by default.<br />
                    You can change their role after they join your team.
                  </small>

                </div>
              </div>

              <Separator size={20} />

              <Loading loading={loadingTeamSettings} />

              {!loadingTeamSettings && team.members.length > 0 && <>
                <ul className="list-group">
                  {team.members.map((member: any, indexMember: number) => {
                    return (
                      <li key={`team-members-list-${member.id}-${indexMember}`} className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col-6">
                            {member.email} {member.email === data.user.email && <small className="text-muted">(You)</small>}

                            {member.joinedVia && <>
                              <small className="text-muted opacity-50">
                                {member.joinedVia === "link" && <>
                                  <i title="Joined via Link" className="fas fa-link ms-2"></i>
                                </>}
                                {member.joinedVia === "invitation" && <>
                                  <i title="Joined via Invitation" className="fas fa-envelope ms-2"></i>
                                </>}
                              </small>
                            </>}
                          </div>
                          <div className="col">
                            <small className="text-muted">
                              {member.status === "invited" && <>
                                <span className="opacity-50">
                                  <i className="fas fa-envelope me-2"></i> Invited
                                </span>
                              </>}

                              {member.status === "active" && <>
                                <span className="text-primary">
                                  <strong>
                                    <i className="fas fa-check-circle me-2"></i> Active
                                  </strong>
                                </span>
                              </>}
                            </small>
                          </div>
                          <div className="col text-end">
                            <select
                              value={member.role}
                              disabled={(loadingUpdateTeamMemberRole && loadingTeamMemberEmail === member.email) || member.email === data.user.email}
                              onChange={(e: any) => _onChangeMemberRole(member, e.target.value)}
                              className="form-control">
                                <option value="admin">Admin</option>
                                <option value="member">Member</option>
                                <option value="viewer">Viewer</option>
                            </select>
                          </div>
                          <div className="col-1 text-end">

                            {member.email !== data.user.email && <>
                              <Loading loading={loadingRemoveMember && loadingTeamMemberEmail === member.email} parent="inline" color="text-primary" />

                              {(!loadingRemoveMember || (loadingRemoveMember && loadingTeamMemberEmail !== member.email)) && <>
                                <button
                                  type="button"
                                  onClick={() => _removeMember(member)}
                                  className="btn btn-link text-danger">
                                    <i className="fas fa-times"></i>
                                </button>
                              </>}

                            </>}

                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </>}

            </div>
            <div className="tab-pane" id="content-management-tab-pane" role="tabpanel" aria-labelledby="content-management-tab">
              <small className="text-muted">
                Manage your team's bookmarks, tags and comments in threads.<br />
                This feature is comming soon.<br />
                We will notify you when it's ready.
              </small>
            </div>
          </div>

        </>}
      </>}
    </div>
  </>
};

export default TeamSettings;