import "./Acronyms.scss";

const Acronyms = (props: any) => {
  const {
    users,
    displayName,
    position,
    limit,
  } = props;

  const FirstLettersList = () => {
    if (!users || users.length === 0) {
      return null;
    }

    const usersToList: any[] = !limit ? users : users.slice(0, limit);

    return (
      <ul className="list-unstyled">
        {usersToList.map((user: any, indexUser: number) => {
          return <li
            key={`Acronyms-item-${indexUser}`}
            title={user.name}
            style={{ backgroundColor: user.color }}
            className="Acronyms-acronym-item">
              {user.name[0]}
          </li>
        })}
      </ul>
    );
  };

  const positionCircle = position === "left" ? "float-start" : "float-end";

  

  return (
    <div className="Acronyms">
      {users && users.length > 0 && <>
        {!displayName && <FirstLettersList />}

        {displayName && displayName !== "" && <>
          <div>
            <div className={`${positionCircle}`}>
              <FirstLettersList />
            </div>
            <div className={`${positionCircle} me-3 ms-2 label`}>
              <small className="text-muted" dangerouslySetInnerHTML={{ __html: displayName }}></small>
            </div>
          </div>
        </>}
      </>}
    </div>
  );
};

export default Acronyms;