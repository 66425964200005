import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { RouterProvider, createBrowserRouter, } from 'react-router-dom';
import { DataProvider } from './context/Data.context';
import { initializeApp } from 'firebase/app';
import { AlertProvider, ConfirmProvider } from '@moreirapontocom/npmhelpers';

import RequireAuth from './services/requireAuth.component';
import Home from './views/Home/Home';
import Feed from './views/Feed/Feed';
import TeamHome from './views/TeamHome/TeamHome';
import Bookmarks from './views/Bookmarks/Bookmarks.component';
import Bookmark from './views/Bookmark/Bookmark';
import Login from './views/Login/Login';
import Signup from './views/Signup/Signup';
import TeamSettings from './views/TeamSettings/TeamSettings';
import Teams from './views/Teams/Teams';
import RecoverPassword from './views/RecoverPassword/RecoverPassword';
import ErrorHandlerProvider from './views/ErrorHandlerProvider/ErrorHandlerProvider';
import Profile from './views/Profile/Profile';
import NewTeamSuccess from './views/NewTeamSuccess/NewTeamSuccess';
import NewAccountSuccess from './views/NewAccountSuccess/NewAccountSuccess';
import Join from './views/Join/Join';

import './index.scss';

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_ID,
});

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_URL || "";

axios.interceptors.request.use(function (config) {
  const token: any = localStorage.getItem('access_token');
  if (!token) return config;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const routes: any = createBrowserRouter([
  // {
  //   path: '/',
  //   element: <Login />,
  // },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/welcome',
    element: <NewAccountSuccess />,
  },
  {
    path: '/recoverPassword',
    element: <RecoverPassword />,
  },
  {
    path: '/',
    element: <RequireAuth component={<Home />} />,
    errorElement: <ErrorHandlerProvider />,
    children: [
      {
        path: '/',
        element: <RequireAuth component={<Feed />} />,
      },
      {
        path: '/profile',
        element: <RequireAuth component={<Profile />} />,
      },
      {
        path: '/teams',
        element: <RequireAuth component={<Teams />} />,
      },
      {
        path: '/teams/welcome/:teamId',
        element: <RequireAuth component={<NewTeamSuccess />} />,
      },
      {
        path: '/join/:teamId',
        element: <RequireAuth component={<Join />} />,
      },
      {
        path: '/teams/:teamId',
        element: <RequireAuth component={<TeamHome />} />,
        children: [
          {
            path: '',
            element: <RequireAuth component={<Bookmarks />} />,
          },
          {
            path: 'settings',
            element: <RequireAuth component={<TeamSettings />} />,
          },
          {
            path: ':bookmarkId',
            element: <RequireAuth component={<Bookmark />} />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AlertProvider>
      <DataProvider>
        <ConfirmProvider>
          <RouterProvider router={routes} />
        </ConfirmProvider>
      </DataProvider>
    </AlertProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
