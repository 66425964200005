import { useContext, useEffect, useState } from "react";
import { AlertContext, Loading, Separator } from "@moreirapontocom/npmhelpers";
import { useNavigate, useParams } from "react-router-dom";
import { getTeam, joinTeam } from "../../services/teams.service";

const Join = () => {
  const params: any = useParams();
  const navigate: any = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const [team, setTeam] = useState(null as any);
  const [currentRole, setCurrentRole] = useState("" as string);
  const [loadingTeam, setLoadingTeam] = useState(true);

  useEffect(() => {
    if (params.teamId) {
      _getTeamInfo(params.teamId);
    }
  }, [params.teamId]);

  const _getTeamInfo = async (teamId: string) => {
    setLoadingTeam(true);
    const response: any = await getTeam(teamId).then(((res: any) => res)).catch(((err: any) => err));
    if (response.message === "OK") {
      setTeam(response.team);
      setCurrentRole(response.currentRole);
      setLoadingTeam(false);
      return;
    }
    console.error("Error getting team", response);
    setLoadingTeam(false);
  };

  const [loadingJoin, setLoadingJoin] = useState(false);
  const joinThisTeam = async () => {
    setLoadingJoin(true);
    const response: any = await joinTeam(team.id).then(((res: any) => res)).catch(((err: any) => err));
    if (response.message === "OK") {
      setLoadingJoin(false);
      setAlert({ type: "success", message: "Welcome to the team!" });
      return navigate(`/teams/${team.id}`);
    }
    setLoadingJoin(false);
    console.log("Error joining team", response);
    setAlert({ type: "danger", message: "Error joining team<br />Please try again later" });
  };

  return <div className="NewTeamSuccess container bg-white p-4 shadow-sm rounded-3">
    <div className="text-center">

      <Loading loading={loadingTeam} />

      {!loadingTeam && team && <>

        <span className="text-muted">Join team</span>

        <Separator size={10} />

        <h4><strong>"{team.name}"</strong></h4>

        <Separator size={10} />

        <small className="text-muted">
          {team.membersCount || 0} members <strong><i>+ you</i></strong>!
        </small>

        <Separator size={60} />
        <img src="/welcome.svg" alt="" className="img-fluid" style={{ maxWidth: "350px" }} />

        <Separator size={50} />

        <button
          type="button"
          className="btn btn-primary"
          disabled={loadingJoin}
          onClick={() => joinThisTeam()}>
            <Loading loading={loadingJoin} parent="inline" color="text-white" />
            {!loadingJoin && <i className="fas fa-check me-2" />}
            Join team
        </button>
      </>}
    </div>

    <Separator size={30} />
  </div>;
};

export default Join;