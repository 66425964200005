import { useContext, useEffect, useState } from "react";
import { listTags } from "../../services/tags.service";
import { Loading, Separator } from "@moreirapontocom/npmhelpers";
import { DataContext } from "../../context/Data.context";
import { capitalizeString } from "./../../services/helpers.service";

import "./Tags.scss";

const Tags = (props: any) => {
  const { teamId }: any = props;
  const { data, setData } = useContext(DataContext);

  useEffect(() => {
    if (teamId || data.newTag) {
      _listTags();
    }
  }, [teamId, data.newTag]);

  const [loadingTags, setLoadingTags] = useState(true);
  const [tags, setTags] = useState([] as any);
  const _listTags = async () => {
    // Don't need to set loading to true here because it's already set to true when the component is mounted
    // So, when the component is mounted, it will call this function and set loading to true
    // But, when the data.newTag changes (meaning there is a new tag), it will call this function again whitout displaying the loading spinner
    const response: any = await listTags(teamId).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      setTags(response.tags);
      setData({ ...data, teamTags: response.tags, newTag: null });
      setLoadingTags(false);
      return;
    }

    console.log("Error loading tags:", response);
    setLoadingTags(false);
  };

  const _setFilterByTag = (tag: any) => {
    setData({ ...data, filterByTag: tag });
  };

  return (
    <div className="Tags">
      <strong>Tags</strong>

      <Separator size={10} />

      <Loading loading={loadingTags} />

      {!loadingTags && <>
        <ul className="list-unstyled tags-sidebar-list">
          <li>
            <div className={`badge ${data.filterByTag && data.filterByTag.id === "noTags" && "bg-primary text-white"}`} onClick={() => _setFilterByTag({ id: "noTags", name: "No Tags", teamId: teamId })}>No Tags</div>
          </li>

          {tags.length > 0 && <>
            {tags.map((tag: any) => {
              return <li key={`Tags-tag-${tag.id}`}>
                <div className={`badge ${data.filterByTag && data.filterByTag.id === tag.id && "bg-primary text-white"}`} onClick={() => _setFilterByTag(tag)}>{capitalizeString(tag.name)}</div>
              </li>
            })}
          </>}
        </ul>
      </>}
    </div>
  );
};

export default Tags;