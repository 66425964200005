import { truncateString } from "@moreirapontocom/npmhelpers";
import { incrementVisitBookmark } from "../../services/bookmarks.service";

const BookmarkLink = (props: any) => {
  const {
    bookmark,
    onVisit,
  } = props;

  const _openLink = async (url: string) => {
    onVisit();
    window.open(url, "_blank");
    const response: any = await incrementVisitBookmark(bookmark.teamId, bookmark.id).then(((res: any) => res)).catch(((err: any) => err));
    if (response.message !== "OK") {
      console.error("Error incrementing visit", response);
    }
  };

  const hasChildren: boolean = props.children;

  return <>
    {props.children && <>
      <div onClick={() => _openLink(bookmark.url)} className="no-underscore text-primary cursor-pointer">
        {props.children}
      </div>
    </>}

    {!props.children && <>
      <small className="text-muted">
        <div onClick={() => _openLink(bookmark.url)} className="no-underscore text-primary cursor-pointer">
          {truncateString(bookmark.url, 70, true)} <i className="fas fa-external-link-alt ms-2"></i>
        </div>
      </small>
    </>}
  </>
};

export default BookmarkLink;