export const getDomainFromUrl = (url: string): string => {
  const domain: string = new URL(url).hostname;
  return domain || "";
};

export const capitalizeString = (str: string): string => {
  if (!str) {
    return "";
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getLinkTemplate = (url: string): any => {
  // Based on the URL, check if it's a link from a known service
  // YouTube, Google Docs or Google Spreadsheets

  const response: any = {
    template: "generic",
  };

  if (url.match(/youtube.com/g) || url.match(/youtu.be/g)) {
    response.template = "youtube";

    const videoIdMatch: any = url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
    if (videoIdMatch && videoIdMatch[1]) {
      const videoId = videoIdMatch[1];
      response.video_cover = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    }
  }

  /*
  Not implemented yet
  if (url.match(/docs.google.com\/document/g)) {
    response.template = "googleDocs";
  }

  if (url.match(/docs.google.com\/spreadsheets/g)) {
    response.template = "googleSpreadsheets";
  }
  */

  return response;
};