import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { Loading, Separator } from "@moreirapontocom/npmhelpers";
import { useContext, useEffect, useState } from "react";
import { getTeam } from "../../services/teams.service";
import { DataContext } from "../../context/Data.context";
import Tags from "../../components/Tags/Tags.component";

const TeamHome = () => {
  const params: any = useParams();
  const [error, setError] = useState(false);
  const {data, setData} = useContext(DataContext);
  const navigate: any = useNavigate();

  useEffect(() => {
    if (params.teamId) {
      getCurrentTeam(params.teamId);
    }
  }, [params.teamId]);

  const [team, setTeam] = useState(null as any);
  const [loadingTeam, setLoadingTeam] = useState(true);
  const [currentRoleAtThisTeam, setCurrentRoleAtThisTeam] = useState("viewer");
  const getCurrentTeam = async (teamId: string) => {
    setError(false);
    setLoadingTeam(true);
    const response: any = await getTeam(teamId).then((r: any) => r).catch((e: any) => e);
    if (response.message === "OK" && response.currentRole !== "not_joined") {
      setTeam(response.team);
      setCurrentRoleAtThisTeam(response.currentRole);
      setData({ ...data, currentTeam: response.team });
      setLoadingTeam(false);
      return;
    }

    setError(true);
    setLoadingTeam(false);
    console.log("Error getting current team", response);
  };

  const [isOnTeamSettings, setIsOnTeamSettings] = useState(false);
  const toggleTeamSettings = (action: string) => {
    if (action === "open") {
      setIsOnTeamSettings(true);
      return navigate(`/teams/${team.id}/settings`);
    }

    setIsOnTeamSettings(false);
    return navigate(`/teams/${team.id}`);
  };

  return <>
    <Loading loading={loadingTeam} />

    {!loadingTeam && <>
      <div className="TeamHome container">

        <small>
          <Link to={`/teams`} type="link" className="no-underscore">
            &larr; back to teams
          </Link>
        </small>

        <Separator size={10} />

      </div>
      {error && <>
        <div className="container">
          <div className="alert alert-danger">
            <strong>Something went wrong</strong><br />
            Are you sure you have access to this page?
          </div>
        </div>
      </>}

      {!loadingTeam && !error && <>
        <div className="TeamHome container bg-white p-4 shadow-sm rounded-3">


          <div className="row align-items-start">
            <div className="col-10">
              <h3>
                <strong>{data.currentTeam.name || team.name}</strong>
              </h3>

              <Separator size={5} />

              {!data.currentTeam.membersCount || data.currentTeam.membersCount === 1 && <>
                <small className="text-muted">
                  <div className="cursor-pointer d-inline-block" onClick={() => toggleTeamSettings("open")}>
                    <i className="fas fa-lock me-2"></i> Private Team
                  </div>
                </small>
              </>}

              {data.currentTeam.membersCount > 1 && <>
                <small className="text-muted">
                  <div className="cursor-pointer d-inline-block" onClick={() => toggleTeamSettings("open")}>
                    <i className="fas fa-users me-2"></i> {data.currentTeam.membersCount || 0} Members
                  </div>
                </small>
              </>}
            </div>
            <div className="col text-end">
              {currentRoleAtThisTeam === "admin" && <>
                {!isOnTeamSettings && <>
                  <button type="button" className="btn btn-link btn-sm no-underscore text-secondary" onClick={() => toggleTeamSettings("open")}>
                    <i className="fas fa-cog me-2"></i> Settings
                  </button>
                </>}

                {isOnTeamSettings && <>
                  <button type="button" className="btn btn-link btn-sm no-underscore text-secondary" onClick={() => toggleTeamSettings("close")}>
                    &larr; back to bookmarks
                  </button>
                </>}
              </>}
            </div>
          </div>

          <Separator size={30} />

          <div className="row">
            <div className="col-2">

              <Tags teamId={team.id} />

            </div>
            <div className="col">

              <Outlet context={{ teamId: team.id, team, currentRole: currentRoleAtThisTeam }} />

            </div>
          </div>


        </div>
      </>}

    </>}
  </>
};

export default TeamHome;