import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AlertContext, Loading, Separator } from "@moreirapontocom/npmhelpers";
import { addAiSuggestions, getAiSuggestions } from "../../services/teams.service";

const NewTeamSuccess = () => {
  const params: any = useParams();
  const navigate: any = useNavigate();
  const [teamId, setTeamId] = useState(null as any);
  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    if (params.teamId) {
      setTeamId(params.teamId);
    }
  }, [params.teamId]);

  const [loadingAiSuggestions, setLoadingAiSuggestions] = useState(false);
  const [aiSuggestions, setAiSuggestions] = useState([] as any);
  const _getAiSuggestions = async (teamId: string) => {
    setLoadingAiSuggestions(true);
    const response: any = await getAiSuggestions(teamId, selectedKeywords).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      console.log("AI suggestions:", response);
      setAiSuggestions(response.suggestedLinks);
      setLoadingAiSuggestions(false);
      return;
    }

    setLoadingAiSuggestions(false);
    console.log("Error getting AI suggestions:", response);
  };

  const keywords: string[] = [
    "Technology",
    "Accounting",
    "Personal finance",
    "Decoration",
    "News",
    "Sustainability",
    "Artificial intelligence",
    "Digital marketing",
    "Personal development",
    "Industrial innovation",
    "E-commerce",
    "Cryptocurrencies",
    "Online education",
    "Global economy",
    "Renewable energy",
    "Mental health",
    "Automation",
    "Project management",
    "Cybersecurity",
    "Augmented reality",
    "Real estate",
    "Interior design",
    "Entrepreneurship",
    "Financial sustainability",
    "Future of work",
    "Online sales",
    "Blockchain",
    "Cloud computing",
    "Digital transformation",
    "Software development",
    "Banking transactions",
    "International relations",
    "Content marketing",
    "Risk investments",
    "Team management",
    "Modern architecture",
    "Quality of life",
    "Smart homes",
    "Corporate law",
    "Circular economy",
  ];

  const [selectedKeywords, setSelectedKeywords] = useState([] as any);

  const [selectedSuggestions, setSelectedSuggestions] = useState([] as any);
  const [loadingAddSelectedSuggestions, setLoadingAddSelectedSuggestions] = useState(false);
  const _addSelectedSuggestions = async () => {
    setLoadingAddSelectedSuggestions(true);
    const response: any = await addAiSuggestions(teamId, selectedSuggestions).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      setAlert({ message: "Suggestions added to the team", type: "success" });
      setLoadingAddSelectedSuggestions(false);
      navigate(`/teams/${teamId}`);
      return;
    }
    setLoadingAddSelectedSuggestions(false);
  };

  return <div className="NewTeamSuccess container bg-white p-4 shadow-sm rounded-3">
    <div className="row">
      <div className="col text-center">

        <h4><strong>Your new team has been created! <span className="ms-2">&#x1F973;</span></strong></h4>
        It's time to never miss a link again.

        <Separator size={30} />

        <strong>
          Select up to 5 keywords so our <i>Artificial Intelligence</i><br />
          can suggest links that might interest you.
        </strong>

        <Separator size={30} />

        {keywords.map((keyword: string) => {
          return <button
            key={`NewTeamSuccess-keyword-${keyword}`}
            type="button"
            onClick={() => {
              if (loadingAiSuggestions) {
                return;
              }

              if (selectedKeywords.includes(keyword)) {
                setSelectedKeywords(selectedKeywords.filter((k: string) => k !== keyword));
                return;
              }

              if (selectedKeywords.length < 5) {
                setSelectedKeywords([...selectedKeywords, keyword]);
              }
            }}
            className={
                `btn 
                ${loadingAiSuggestions ? "disabled" : ""}
                ${selectedKeywords.find((item: any) => item === keyword) ? "btn-primary" : "btn-outline-primary"} 
                ${!selectedKeywords.find((item: any) => item === keyword) && selectedKeywords.length === 5 ? "disabled" : ""} 
                me-1 mb-2 d-inline-block
              `}>
              {keyword}
          </button>
        })}

        <Separator size={30} />

        <button
          onClick={() => _getAiSuggestions(teamId)}
          disabled={loadingAiSuggestions || selectedKeywords.length === 0 || selectedKeywords.length > 5}
          className="btn btn-primary me-2">
            <Loading loading={loadingAiSuggestions} parent="inline" color="text-white" />
            {!loadingAiSuggestions && <i className="fas fa-search me-2"></i>} Find Suggestions
        </button>

        {teamId && <>
          <Separator size={15} />
          <Link to={`/teams/${teamId}`} className="btn btn-link no-underscore">
            <small>
              Skip. Just go to the team &rarr;
            </small>
          </Link>
        </>}

      </div>
      <div className="col">

        {loadingAiSuggestions && <>
          <div className="text-center">
            <Separator size={50} />
            <Loading loading={loadingAiSuggestions} />
            <Separator size={10} />
            <strong>
              Our AI is searching for suggestions<br />
              based on the selected keywords
            </strong>
          </div>
        </>}

        {!loadingAiSuggestions && aiSuggestions.length > 0 && <>
          <div className="text-start">
            <strong>Here are some links that might interest you based on the keywords:</strong>

            <Separator size={20} />

            {aiSuggestions.map((group: any) => {
              return <div key={`NewTeamSuccess-group-${group.keyword}`}>
                <strong><i className="fas fa-caret-right me-2"></i> {group.keyword}</strong>

                <Separator size={5} />

                <ul className="list-unstyled">
                  {group.links.map((link: any) => {
                    return <React.Fragment key={`NewTeamSuccess-link-${link.link}`}>
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            name="selectedSuggestions"
                            disabled={loadingAddSelectedSuggestions}
                            value={link.link}
                            checked={selectedSuggestions.find((item: any) => item.link === link.link)}
                            onChange={() => {
                              if (selectedSuggestions.find((item: any) => item.link === link.link)) {
                                setSelectedSuggestions(selectedSuggestions.filter((s: any) => s.link !== link.link));
                                return;
                              } else {
                                setSelectedSuggestions([...selectedSuggestions, link]);
                              }
                            }}
                            className="me-3" />
                        </label>
                        <a href={link.link} target="_blank" rel="noreferrer">
                          {link.title}
                        </a>
                      </li>
                    </React.Fragment>
                  })}
                </ul>

                <Separator size={30} />
              </div>
            })}

            <button
              disabled={selectedSuggestions.length === 0 || loadingAddSelectedSuggestions}
              onClick={() => _addSelectedSuggestions()}
              className="btn btn-primary">
                <Loading loading={loadingAddSelectedSuggestions} parent="inline" color="text-white" />
                {!loadingAddSelectedSuggestions && <i className="fas fa-check me-2"></i>}
                Add Selected Suggestions to the Team
            </button>
          </div>
        </>}

      </div>
    </div>

    <Separator size={30} />
  </div>
};

export default NewTeamSuccess;