import { useContext, useState } from "react";
import { ConfirmContext } from "@moreirapontocom/npmhelpers";
import { removeBookmark } from "../../services/bookmarks.service";

const BookmarkRemove = (props: any) => {
  const {
    className,
    teamId,
    bookmarkId,
    onSuccess,
    onError,
  } = props;
  const { setConfirm } = useContext(ConfirmContext);

  return <>
    <button
      className={`btn btn-link text-danger no-underscore ${className}`}
      onClick={() => {
        setConfirm({
          type: "danger", // primary || success || danger || info || warning
          title: "Remove Bookmark?",
          message: "This action cannot be undone. All comments in this thread will be lost.",
          buttonLabel: "Remove",
          buttonCancelLabel: "Cancel",
          onConfirm: async () => {
            onSuccess();

            const response: any = await removeBookmark(teamId, bookmarkId).then((res: any) => res).catch((err: any) => err);
            if (response.message !== "OK") {
              onError();
              return;
            }
          },
        });
      }}>
        <i className="fas fa-trash-alt"></i>
    </button>
  </>;
};

export default BookmarkRemove;