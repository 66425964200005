import { AlertContext, Loading, Separator, convertFirebaseTimestampToString, detectUrlInString, truncateString } from "@moreirapontocom/npmhelpers";
import { useContext, useEffect, useState } from "react";
import { getBookmark } from "../../services/bookmarks.service";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { listComments, postComment } from "../../services/comments.service";
import { useFormik } from "formik";
import { DataContext } from "../../context/Data.context";
import { Popover } from "react-bootstrap";
import { getDomainFromUrl, getLinkTemplate } from "../../services/helpers.service";
import MessageBalloon from "../../components/MessageBalloon/MessageBalloon.component";
import TextareaAutosize from 'react-textarea-autosize';
import Acronyms from "../../components/Acronyms/Acronyms.component";
import BookmarkDescription from "../../components/BookmarkDescription/BookmarkDescription.component";
import BookmarkRemove from "../../components/BookmarkRemove/BookmarkRemove.component";
import BookmarkLink from "../../components/BookmarkLink/BookmarkLink.component";
import BookmarkTags from "../../components/BookmarkTags/BookmarkTags.component";

import "./Bookmark.scss";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const Bookmark = () => {
  const params: any = useParams();
  const [bookmarkId, setBookmarkId] = useState("");
  const { teamId }: any = useOutletContext();
  const { data } = useContext(DataContext);
  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    if (params.bookmarkId && teamId) {
      setBookmarkId(params.bookmarkId);
      _getBookmark(params.bookmarkId);
      _getComments(params.bookmarkId);
    }
  }, [bookmarkId, teamId]);

  const [loadingBookmark, setLoadingBookmark] = useState(false);
  const [currentRole, setCurrentRole] = useState(null as any);
  const [bookmark, setBookmark] = useState({} as any);
  const [membersMentionsList, setMembersMentionsList] = useState([] as any[]);
  const _getBookmark = async (bookmarkId: string) => {
    setLoadingBookmark(true);
    const response: any = await getBookmark(teamId, bookmarkId).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      setBookmark(response.bookmark);
      setCurrentRole(response.currentRole);
      setMembersMentionsList(response.membersMentionsList);
      setLoadingBookmark(false);
      return;
    }

    setAlert({ type: "danger", message: "Error loading bookmark" });
    setLoadingBookmark(false);
  };

  const [loadingComments, setLoadingComments] = useState(false);
  const [comments, setComments] = useState([] as any);

  const _getComments = async (bookmarkId: string) => {
    setLoadingComments(true);
    const response: any = await listComments(teamId, bookmarkId).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      setComments(response.comments);
      setLoadingComments(false);
      return;
    }

    console.log("Error loading comments:", response);
    setLoadingComments(false);
  };

  const [loadingPostComment, setLoadingPostComment] = useState(false);
  const formComment: any = useFormik({
    initialValues: {
      comment: "",
    },
    onSubmit: async (values) => {
      setLoadingPostComment(true);
      const response: any = await postComment(teamId, {bookmarkId: bookmarkId, comment: values.comment}).then((res: any) => res).catch((err: any) => err);
      if (response.message === "OK") {
        comments.unshift(response.newComment);
        bookmark.totalComments = response.bookmarkTotalComments;
        setBookmark(bookmark);
        formComment.resetForm();
        setLoadingPostComment(false);
        return;
      }

      console.log("Error posting comment:", response);
      setLoadingPostComment(false);
    },
  });

  const [tags] = useState(data.teamTags || [] as any);

  // Mentions
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleSuggestionClick = (suggestion: any) => {
    const text: string = formComment.values.comment;
    const newText = text.replace('@', `[${suggestion.handler}]`);
    formComment.setFieldValue('comment', newText);
    setShowSuggestions(false);
  };

  const MentionsPopover = () => {
    return <>
      {!loadingBookmark && membersMentionsList.length > 0 && showSuggestions && <>
        <div style={{ position: "absolute", minWidth: "300px", maxWidth: "800px" }}>
          <Popover>
            <div className="list-group list-group-flush d-grid rounded-0 pt-1 pb-1">
              {membersMentionsList.map((suggestion: any, index: any) => (
                suggestion.handler !== data.user.email.split('@')[0] && <>
                  <button
                    type="button"
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    style={{ cursor: 'pointer', padding: '5px' }}
                    className="list-group-item list-group-item-action ps-3 pe-3"
                  >
                    {suggestion.name} <small className="text-muted">
                      @{suggestion.handler}
                    </small>
                  </button>
                </>
              ))}
            </div>
          </Popover>
        </div>
      </>}
    </>
  };

  return <>
    <Loading loading={loadingBookmark} />

    {!loadingBookmark && bookmark.id && <>
      <div className="container Bookmark">

        <Link to={`/teams/${teamId}`} className="btn btn-link no-underscore ps-0">&larr; back to {data.filterByTag && data.filterByTag.id && <>"{data.filterByTag.name}"</>} bookmarks</Link>

        <Separator size={30} />

        <div className="row">

          {bookmark.bookmarkType && bookmark.bookmarkType === "link" && getLinkTemplate(bookmark.url).template === "youtube" && <>
            <div className="col-4" style={{ backgroundColor: "black" }}>
              <BookmarkLink
                bookmark={bookmark}
                onVisit={() => setBookmark({ ...bookmark, totalClicks: (bookmark.totalClicks || 0) + 1 })}
              >
                <img src={getLinkTemplate(bookmark.url).video_cover} className="img-fluid" />
              </BookmarkLink>
            </div>
          </>}

          <div className="col">


            {/* TYPE LINK */}
            {(!bookmark.bookmarkType || (bookmark.bookmarkType && bookmark.bookmarkType === "link")) && <>
              <img
                src={bookmark.favicon}
                alt={bookmark.title}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/favicon.ico";
                }}
                className="img-fluid favicon" />
              <strong>
                {["", "title_not_found"].includes(bookmark.title) && getDomainFromUrl(bookmark.url)}
                {!["", "title_not_found"].includes(bookmark.title) && truncateString(bookmark.title, 186, true)}
              </strong>

              <Separator size={5} />

              <BookmarkLink
                bookmark={bookmark}
                onVisit={() => setBookmark({ ...bookmark, totalClicks: (bookmark.totalClicks || 0) + 1 })}
              />

              <Separator size={10} />

              <BookmarkDescription
                teamId={teamId}
                bookmark={bookmark}
                bookmarks={null}
                currentRole={currentRole}
                onSuccess={(e: any) => {
                  const { newBookmarkDescription } = e;
                  const newBookmark: any = bookmark;
                  bookmark.description = newBookmarkDescription;
                  setBookmark(newBookmark);
                }}
                onError={(e: any) => {
                  const { oldBookmarkDescription } = e;
                  const oldBookmark: any = bookmark;
                  bookmark.description = oldBookmarkDescription;
                  setBookmark(oldBookmark);
                }}
                />
            </>}


            {/* TYPE NOTE */}
            {(bookmark.bookmarkType && bookmark.bookmarkType === "note") && <>
              <i className="fas fa-comment-alt"></i>
              <Separator size={5} />
              <span className="line-break break-content" dangerouslySetInnerHTML={{ __html: detectUrlInString(bookmark.note) }}></span>
            </>}

          </div>

          {currentRole === "admin" && <>
            <div className="col-1 text-end">

              <BookmarkRemove
                teamId={teamId}
                bookmarkId={bookmark.id}
                onSuccess={() => {
                  setAlert({ type: "success", message: "Bookmark removed" });
                  window.location.href = `/teams/${teamId}`;
                  return;
                }}
                onError={() => {
                  setAlert({ type: "danger", message: "Error removing bookmark" });
                }}
              />

            </div>
          </>}
        </div>

        <Separator size={20} />

        <small className="text-muted">
          <i className="fas fa-chart-bar me-2"></i> {bookmark.totalClicks || 0} Clicks
        </small>

        <Separator size={10} />

        <div className="row align-items-center">
          <div className="col-8">

            <BookmarkTags
              currentRole={currentRole}
              teamId={teamId}
              bookmark={bookmark}
              tagsAvailable={tags}
            />

            {currentRole === "viewer" && <>
              {bookmark.tags?.length === 0 && <>
                <div className="d-flex">
                  <span className="badge">No Tags</span>
                </div>
              </>}

              {bookmark.tags?.length > 0 && <>
                <div className="d-flex">
                  {bookmark.tags?.map((tag: any) => {
                    return (
                      <span key={`Bookmark-tag-${tag.id}`} className="badge me-1">{tag.name}</span>
                    );
                  })}
                </div>
              </>}
            </>}

          </div>
          <div className="col text-end">

            <Acronyms
              displayName={dayjs(convertFirebaseTimestampToString(bookmark.createdAt)).fromNow()}
              users={[
                {
                  name: bookmark.author.name,
                },
              ]} />

          </div>
        </div>

        <Separator size={30} />

        <strong>Thread</strong>

        <Separator size={20} />

        {["admin", "member"].includes(currentRole) && <>
          <form onSubmit={formComment.handleSubmit}>
            <TextareaAutosize
              minRows={1}
              maxRows={5}
              className="form-control rounded-20"
              placeholder="Type a message. Use @ to mention someone in this team."
              disabled={loadingPostComment}
              value={formComment.values.comment}
              onChange={formComment.handleChange}
              name="comment"
              onKeyUp={() => setShowSuggestions(formComment.values.comment.includes('@') ? true : false )}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  formComment.handleSubmit();
                }

                if (e.key === '@') {
                  setShowSuggestions(true);
                }
              }}
              />

              <MentionsPopover />
          </form>

          <Separator size={20} />
        </>}

        <small className="text-muted">{bookmark.totalComments || 0} Comments</small>

        <Loading loading={loadingComments} />

        {!loadingComments && comments.length > 0 && <>
          <Separator size={20} />

          <ul className="list-unstyled comments-list">
            {comments.map((comment: any) => {
              return (
                <li key={`Bookmark-comment-${comment.id}`}>
                  <MessageBalloon comment={comment} />
                </li>
              );
            })}
          </ul>
        </>}

      </div>
    </>}
  </>
};

export default Bookmark;