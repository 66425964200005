import { useContext, useState } from "react";
import { AlertContext, Separator } from "@moreirapontocom/npmhelpers";
import { updateBookmarkField } from "../../services/bookmarks.service";

const BookmarkDescription = (props: any) => {
  const {
    teamId,
    bookmark,
    bookmarks,
    currentRole,
    onSuccess,
    onError,
  } = props;

  const { setAlert } = useContext<any>(AlertContext);

  const [editDescriptionId, setEditDescriptionId] = useState(null as any);
  const [editDescriptionValue, setEditDescriptionValue] = useState("" as string);

  const _updateBookmarkDescription = async () => {
    const fieldToUpdate: string = "description";

    let bookmarkIndex;
    let oldDescription;

    if (bookmarks && bookmarks.length > 0) {
      // To update the bookmark description in the bookmarks array (bookmarks page)
      bookmarkIndex = bookmarks.findIndex((bookmark: any) => bookmark.id === editDescriptionId);
      oldDescription = bookmarks[bookmarkIndex].description;
      bookmarks[bookmarkIndex][fieldToUpdate] = editDescriptionValue;
      bookmarks[bookmarkIndex][`${fieldToUpdate}_updatedAt`] = true;
      const newBookmarks = [...bookmarks];
      onSuccess({bookmarks: newBookmarks});
    } else {
      // To update the bookmark description in the bookmark object (bookmark page)
      onSuccess({newBookmarkDescription: editDescriptionValue});
    }

    const payload: any = {
      field: fieldToUpdate,
      value: editDescriptionValue || "",
    };

    setEditDescriptionId(null);
    setEditDescriptionValue("");

    const response: any = await updateBookmarkField(teamId, editDescriptionId, payload).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      setAlert({ type: "success", message: "Description updated successfully" });
      return;
    }

    setAlert({ type: "danger", message: "Error updating description" });
    if (bookmarks && bookmarks.length > 0) {
      // To update the bookmark description in the bookmarks array (bookmarks page)
      bookmarks[bookmarkIndex][fieldToUpdate] = oldDescription;
      bookmarks[bookmarkIndex][`${fieldToUpdate}_updatedAt`] = false;
      onError([...bookmarks]);
    } else {
      // To update the bookmark description in the bookmark object (bookmark page)
      onError({oldBookmarkDescription: oldDescription});
    }
  };

  return <>
    {currentRole === "admin" && <>
      {(editDescriptionId !== bookmark.id) && <>
        <div onClick={() => {
          setEditDescriptionValue(bookmark.description === "description_not_found" ? "" : bookmark.description);
          setEditDescriptionId(bookmark.id);
        }} className="cursor-pointer d-inline-block">
          <small className="text-muted">
            <i className="fas fa-pen me-2"></i> {["", "description_not_found"].includes(bookmark.description) && <i>Click to edit description</i>}
          </small>
        </div>
      </>}

      {((!editDescriptionId || editDescriptionId !== bookmark.id) && !["", "description_not_found"].includes(bookmark.description)) && <>
        <span dangerouslySetInnerHTML={{ __html: bookmark.description }}></span>

        {bookmark.description_updatedAt && <>
          <span className="ms-1">
            <small className="text-muted"><i>(edited)</i></small>
          </span>
        </>}
      </>}

      {editDescriptionId === bookmark.id && <>
        <input
          type="text"
          className="form-control"
          autoComplete="off"
          placeholder="Add a description (Enter to save)"
          value={editDescriptionValue}
          onChange={(e) => setEditDescriptionValue(e.target.value)}
          onBlur={() => _updateBookmarkDescription()}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              _updateBookmarkDescription();
            }
          }}
          />
      </>}

      <Separator size={20} />
    </>}

    {currentRole !== "admin" && bookmark.description !== "" && <>
      <span dangerouslySetInnerHTML={{ __html: bookmark.description }}></span>
      <Separator size={20} />
    </>}
  </>
};

export default BookmarkDescription;