import axios from "axios";

export const listTeams = async () => await axios.get(`/teams`).then((res) => res.data).catch((err) => err);

export const getAiSuggestions = async (teamId: string, selectedKeywords: string[]) => await axios.post(`/teams/welcome/${teamId}`, {selectedKeywords}).then((res) => res.data).catch((err) => err);

export const addAiSuggestions = async (teamId: string, selectedSuggestions: any[]) => await axios.post(`/teams/populateAiSuggestions/${teamId}`, {selectedSuggestions}).then((res) => res.data).catch((err) => err);

export const getTeam = async (teamId: string) => await axios.get(`/teams/${teamId}`).then((res) => res.data).catch((err) => err);

export const joinTeam = async (teamId: string) => await axios.post(`/teams/${teamId}/join`).then((res) => res.data).catch((err) => err);

export const createTeam = async () => await axios.post("/teams").then((res) => res.data).catch((err) => err);

export const updateTeam = async (teamId: string, payload: any) => await axios.put(`/teams/${teamId}/updateSettings`, payload).then((res) => res.data).catch((err) => err);

export const updateTeamMemberRole = async (teamId: string, payload: any) => await axios.put(`/teams/${teamId}/updateMemberRole`, payload).then((res) => res.data).catch((err) => err);

export const inviteTeamMember = async (teamId: string, payload: any) => await axios.post(`/teams/${teamId}/inviteMember`, payload).then((res) => res.data).catch((err) => err);

export const removeTeamMember = async (teamId: string, payload: any) => await axios.post(`/teams/${teamId}/removeMember`, payload).then((res) => res.data).catch((err) => err);