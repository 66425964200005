import { useContext, useEffect, useState } from "react";
import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { DataContext } from "../../context/Data.context";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { signJWT } from "../../services/jwt.service";
import { logout } from "../../services/auth.service";
import { Alert, AlertContext, Loading, Separator } from "@moreirapontocom/npmhelpers";
import User from "../../interfaces/user.interface";
import * as yup from 'yup';

import "./Login.scss";

const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

const Login = () => {
  const {data, setData} = useContext(DataContext);
  const {alert, setAlert} = useContext(AlertContext);
  const navigate: any = useNavigate();

  useEffect(() => {
    const doLogout = async (): Promise<any> => {
      await logout();

      const emptyUser: User = {
        uid: null,
        displayName: null,
        email: null,
        accessToken: null,
      };

      setData({...data, user: emptyUser});
      navigate("/login");
      return;
    };
    doLogout();
  }, []);

  const _getNextPage = (): string => {
    const localNextPage: any = localStorage.getItem("nextPage");
    return (localNextPage && localNextPage !== "/login") ? localNextPage : null;
  };

  const emailPasswordInitialValues: { email: string, password: string } = {
    email: "",
    password: "",
  };

  const [loadingAuthentication, setLoadingAuthentication] = useState(false);

  const formikEmailPassword: any = useFormik({
    initialValues: emailPasswordInitialValues,
    validationSchema: yup.object({
      email: yup.string().email().required(),
      password: yup.string().min(6).required(),
    }),
    onSubmit: (values: any) => {
      const auth: any = getAuth();
      setLoadingAuthentication(true);
      signInWithEmailAndPassword(auth, values.email, values.password).then(async (result: any) => {
        const {uid, email}: User = result.user;
        const jwt: any = await signJWT({uid, email} as User);
        setData({...data, user: {uid, email, accessToken: jwt}});

        localStorage.setItem("access_token", jwt);
        localStorage.setItem("user", JSON.stringify({uid, email}));

        const nextPage: string = _getNextPage() || "/";

        navigate(nextPage);
        localStorage.removeItem("nextPage");
        return;
      }).catch((error: any) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('Error Google SignIn:', errorCode, errorMessage);
        setAlert({type: "danger", message: errorMessage});
        setLoadingAuthentication(false);
      });

    },
  });

  return <>
    <Alert alert={alert} />

    <div className="Login container">
      <div className="bg"></div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-4">

          <div className="d-flex" style={{ height: "100vh", flexDirection: "column", justifyContent: "center" }}>

            <div className="text-center">
              <img src="/urlcollab.svg" alt="" className="img-fluid" style={{ maxWidth: "250px" }} />
            </div>

            <Separator size={40} />

            <div className="card bg-white shadow-sm rounded-3 border-0">
              <div className="card-body">

                {(!data.user || !data.user.uid) && <>
                  <div className="text-center">
                    <p>
                      <strong>Welcome back</strong><br />
                      Sign in to your account
                    </p>

                    <Separator size={20} />

                    <div className="row justify-content-center">
                      <div className="col-12 col-md-10">

                        <form onSubmit={(e: any) => {
                          e.preventDefault();
                          formikEmailPassword.handleSubmit();
                        }}>
                          <div className="form-group mb-3">
                            <input
                              type="email"
                              disabled={loadingAuthentication}
                              name="email"
                              data-testid="login-email"
                              autoComplete="off"
                              onChange={formikEmailPassword.handleChange}
                              value={formikEmailPassword.values.email}
                              className="form-control"
                              placeholder="E-mail" />
                          </div>

                          <div className="form-group mb-3">
                            <input
                              type="password"
                              name="password"
                              data-testid="login-password"
                              autoComplete="off"
                              onChange={formikEmailPassword.handleChange}
                              value={formikEmailPassword.values.password}
                              disabled={loadingAuthentication}
                              className="form-control"
                              placeholder="Password" />
                          </div>

                          <div className="d-grid">
                            <button
                              type="submit"
                              data-testid="login-submit"
                              disabled={loadingAuthentication || !formikEmailPassword.isValid}
                              className="btn btn-primary btn-block">
                                <Loading loading={loadingAuthentication} parent="inline" color="text-white" />
                                {!loadingAuthentication && <i className="fas fa-sign-in-alt me-2"></i>} Sign In
                            </button>
                          </div>

                        </form>


                        <Separator size={40} />

                        <div className="text-center">
                          <small className="text-muted">
                            - or -
                          </small>
                        </div>

                        <Separator size={40} />

                        <div className="d-grid">
                          <Link to="/signup" className="btn btn-outline-primary">
                            Create Account
                          </Link>

                          <Separator size={15} />

                          <Link to="/recoverPassword" className="btn btn-link no-underscore">Recover Password</Link>
                        </div>

                      </div>
                    </div>


                    {/*
                    <button
                      disabled={false || loadingAuthentication}
                      className="btn btn-outline-primary btn-google-signin"
                      type="button" onClick={() => authenticate_GoogleSignin()}
                      >
                        <img src="images/g-logo.png" alt="Sign in with Google" className="img-fluid" /> Entrar com Google
                    </button>
                    */}
                  </div>
                </>}

              </div>
            </div>{/* /.card */}

          </div>

        </div>
      </div>
    </div>
  </>
};

export default Login;