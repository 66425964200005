import { useContext, useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { createBookmark, listBookmarks } from "../../services/bookmarks.service";
import { AlertContext, Loading, Separator, convertFirebaseTimestampToString, detectUrlInString, truncateString } from "@moreirapontocom/npmhelpers";
import { useFormik } from "formik";
import { DataContext } from "../../context/Data.context";
import { getDomainFromUrl, getLinkTemplate, capitalizeString } from "../../services/helpers.service";
import MessageBalloon from "../../components/MessageBalloon/MessageBalloon.component";
import Acronyms from "../../components/Acronyms/Acronyms.component";
import TextareaAutosize from 'react-textarea-autosize';
import BookmarkDescription from "../../components/BookmarkDescription/BookmarkDescription.component";
import BookmarkRemove from "../../components/BookmarkRemove/BookmarkRemove.component";
import BookmarkLink from "../../components/BookmarkLink/BookmarkLink.component";
import BookmarkTags from "../../components/BookmarkTags/BookmarkTags.component";
import * as yup from "yup";

import "./Bookmarks.scss";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const Bookmarks = () => {
  const { teamId, team, currentRole }: any = useOutletContext();
  const { data, setData }: any = useContext(DataContext);
  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    if (teamId) {
      if (data.filterByTag) {
        _listBookmarks(data.filterByTag);
        return;
      }

      _listBookmarks();
    }
  }, [teamId, data.filterByTag]);

  const [lastVisibleDocId, setLastVisibleDocId] = useState(null as any);

  const [loadingBookmarks, setLoadingBookmarks] = useState(false);
  const [bookmarks, setBookmarks] = useState([] as any);
  const _listBookmarks = async (filterByTag?: any) => {
    setLoadingBookmarks(true);
    const response: any = await listBookmarks(teamId, filterByTag).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      setBookmarks(response.bookmarks);

      if (response.bookmarks.length > 0) {
        setLastVisibleDocId(response.bookmarks[response.bookmarks.length-1].id);
      }

      setLoadingBookmarks(false);
      return;
    }

    console.log("Error loading bookmarks:", response);
    setLoadingBookmarks(false);
  };

  const [loadingMoreBookmarks, setLoadingMoreBookmarks] = useState(false);
  const _loadMoreBookmarks = async () => {
    setLoadingMoreBookmarks(true);
    const response: any = await listBookmarks(teamId, data.filterByTag, lastVisibleDocId).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      setBookmarks([...bookmarks, ...response.bookmarks]);
      if (response.bookmarks.length > 0) {
        setLastVisibleDocId(response.bookmarks[response.bookmarks.length-1].id);
      }

      setLoadingMoreBookmarks(false);
      return;
    }

    console.log("Error loading bookmarks:", response);
    setLoadingMoreBookmarks(false);
  };

  const formBookmark: any = useFormik({
    initialValues: {
      bookmarkType: "link",
      url: "",
      note: "",
      // attachment: "",
    },
    validateOnMount: true,
    validationSchema: yup.object({
      bookmarkType: yup.string().required("Bookmark type is required").oneOf(["link", "note", "attachment"], "Invalid bookmark type"),
      url: yup.string().when("bookmarkType", ([bookmarkType], schema) => {
        if (bookmarkType === "link") {
          return schema.required("URL is required").url("Invalid URL");
        }
        return schema.optional().default("");
      }),
      note: yup.string().when("bookmarkType", ([bookmarkType], schema) => {
        if (bookmarkType === "note") {
          return schema.required("Note is required");
        }
        return schema.optional().default("");
      }),

      // attachment is required only if bookmarkType is "attachment"
      /*
      attachment: yup.string().when("bookmarkType", ([bookmarkType], schema) => {
        if (bookmarkType === "attachment") {
          return schema.required("Attachment is required");
        }
        return schema.required().default("");
      }),
      */
    }),
    onSubmit: async (values: any) => {
      const response: any = await createBookmark(teamId, values).then((res: any) => res).catch((err: any) => err);
      if (response.message === "OK") {
        bookmarks.unshift(response.newBookmark);

        // Update team bookmarks count
        setData({ ...data, team: { ...team, bookmarksCount: team.bookmarksCount + 1 } });
        team.bookmarksCount = team.bookmarksCount + 1;

        formBookmark.resetForm();
        return;
      }
    },
  });

  const _clearFilterByTag = () => {
    setData({ ...data, filterByTag: null });
  };

  return (
    <div className="Bookmarks">

      {["admin", "member"].includes(currentRole) && <>
        <form onSubmit={formBookmark.handleSubmit}>

          <div className="card bg-light shadow-0 border-0">
            <div className="card-body">

              <small className="text-muted">
                Choose the bookmark type
              </small>

              <Separator size={10} />

              <div className="" role="group">
                <label
                  htmlFor="btnradio1">
                    <input
                      type="radio"
                      className="me-2"
                      name="btnradio"
                      id="btnradio1"
                      autoComplete="off"
                      onClick={() => formBookmark.setFieldValue("bookmarkType", "link")}
                      checked={formBookmark.values.bookmarkType === "link"}
                      onChange={formBookmark.handleChange}
                      value="link" />
                    Link
                </label>

                <label
                  className="ms-5"
                  htmlFor="btnradio2">
                    <input
                      type="radio"
                      className="me-2"
                      name="btnradio"
                      id="btnradio2"
                      autoComplete="off"
                      onClick={() => formBookmark.setFieldValue("bookmarkType", "note")}
                      onChange={formBookmark.handleChange}
                      checked={formBookmark.values.bookmarkType === "note"}
                      value="note" />
                    Note
                </label>

                {/*
                <input
                  type="radio"
                  className="btn-check"
                  name="btnradio"
                  id="btnradio3"
                  autoComplete="off"
                  disabled
                  onClick={() => formBookmark.setFieldValue("bookmarkType", "attachment")}
                  onChange={formBookmark.handleChange}
                  checked={formBookmark.values.bookmarkType === "attachment"}
                  value="attachment" />
                <label
                  className="btn btn-outline-primary btn-sm"
                  htmlFor="btnradio3">
                    <i className="fas fa-paperclip me-2"></i> Attachment (soon)
                </label>
                */}
              </div>

              <Separator size={20} />

              <div className="row">
                <div className="col">

                  {/* LINK */}
                  {formBookmark.values.bookmarkType === "link" && <>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      placeholder="Paste website URL here to bookmark it"
                      name="url"
                      disabled={formBookmark.isSubmitting}
                      onChange={formBookmark.handleChange}
                      value={formBookmark.values.url}
                      />
                  </>}

                  {/* NOTE */}
                  {formBookmark.values.bookmarkType === "note" && <>
                    <TextareaAutosize
                      minRows={2}
                      maxRows={5}
                      className="form-control rounded-20"
                      placeholder="Type a note"
                      disabled={formBookmark.isSubmitting}
                      value={formBookmark.values.note}
                      onChange={formBookmark.handleChange}
                      name="note"
                      onKeyDown={(e: any) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          formBookmark.handleSubmit();
                        }
                      }} />
                  </>}

                  {/* ATTACHMENT */}
                  {/*
                  {formBookmark.values.bookmarkType === "attachment" && <>
                    <input
                      type="file"
                      className="form-control"
                      name="attachment"
                      disabled={formBookmark.isSubmitting}
                      onChange={formBookmark.handleChange}
                      value={formBookmark.values.attachment} />
                  </>}
                  */}

                </div>
                <div className="col-2 text-end">

                  <button
                    disabled={formBookmark.isSubmitting || !formBookmark.isValid}
                    className="btn btn-primary"
                    type="submit">
                      <Loading loading={formBookmark.isSubmitting} parent="inline" color="text-white" />
                      {!formBookmark.isSubmitting && <i className="fas fa-bookmark me-2"></i>} Bookmark
                  </button>

                </div>
              </div>
            </div>

          </div>
        </form>

        <Separator size={20} />
      </>}

      {currentRole === "viewer" && <>
        <div className="alert alert-secondary">
          <small>
            <strong><i className="fas fa-eye me-2"></i> You're a viewer in this team. </strong> You can only view bookmarks and threads.
          </small>
        </div>

        <Separator size={20} />
      </>}

      <strong>
        Bookmarks 

        {data.filterByTag && <>
          <span className="text-muted ms-1 me-2">
            &raquo; <span className="text-primary">
              {data.filterByTag.name} <i onClick={() => _clearFilterByTag()} className="fas fa-times-circle ms-2 cursor-pointer"></i>
            </span>
          </span>
        </>}
      </strong>

      <Separator size={5} />

      <div className="row align-items-end">
        <div className="col">
          <small className="text-muted">
            {loadingBookmarks && <>Counting...</>}
            {!loadingBookmarks && <>
              {!data.filterByTag && <>
                {team.bookmarksCount || 0} Bookmarks found
              </>}

              {data.filterByTag && <>
                {bookmarks.length || 0} Bookmarks found {data.filterByTag && <span className={`tag-${(data.filterByTag.id).slice(0, 5)}`}>on {data.filterByTag.name}</span>}
              </>}
            </>}
          </small>
        </div>
        <div className="col text-end">
          <small className="text-muted">
            <div className="cursor-pointer d-inline-block" onClick={() => {
              _clearFilterByTag();
              _listBookmarks();
            }}>
              <Loading loading={loadingBookmarks} parent="inline" color="text-primary" />
              {!loadingBookmarks && <i className="fas fa-sync me-2"></i>}
            </div>
          </small>
        </div>
      </div>

      <Separator size={20} />

      <Loading loading={loadingBookmarks} />

      {!loadingBookmarks && bookmarks.length === 0 && <>
        <div className="text-center">
          <strong>You have no bookmarks yet</strong>

          <Separator size={20} />

          Start by bookmarking a website or adding a note to your team.

          <Separator size={20} />

          <img src="/welcome-to-team.svg" alt="" className="img-fluid" width="400px" />

          <Separator size={20} />
        </div>
      </>}

      {!loadingBookmarks && bookmarks.length > 0 && <>
        <ul className="list-unstyled bookmarks-list">
          {bookmarks.map((bookmark: any) => {
            return <li key={`Bookmarks-bookmark-item-${bookmark.id}`}>
              <div className="card shadow-sm">

                <div className="row">

                  {bookmark.bookmarkType && bookmark.bookmarkType === "link" && getLinkTemplate(bookmark.url).template === "youtube" && <>
                    <div className="col-4">
                      <div style={{ backgroundColor: "black", margin: "10px" }}>
                        <BookmarkLink
                          bookmark={bookmark}
                          onVisit={() => {
                            bookmark.totalClicks = (bookmark.totalClicks || 0) + 1;
                            setBookmarks([...bookmarks]);
                          }}
                        >
                          <img src={getLinkTemplate(bookmark.url).video_cover} className="img-fluid" />
                        </BookmarkLink>
                      </div>
                    </div>
                  </>}

                  <div className="col">

                    <div className="card-body display-on-hover">

                      <div className="row">
                        <div className="col">

                          {/* TYPE LINK */}
                          {(!bookmark.bookmarkType || (bookmark.bookmarkType && bookmark.bookmarkType === "link")) && <>
                            <img
                              src={bookmark.favicon}
                              alt={bookmark.title}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/favicon.ico";
                              }}
                              className="img-fluid favicon" />

                            <strong>
                              {["", "title_not_found"].includes(bookmark.title) && getDomainFromUrl(bookmark.url)}
                              {!["", "title_not_found"].includes(bookmark.title) && truncateString(bookmark.title, 86, true)}
                            </strong>

                            <Separator size={9} />

                            <BookmarkLink
                              bookmark={bookmark}
                              onVisit={() => {
                                bookmark.totalClicks = (bookmark.totalClicks || 0) + 1;
                                setBookmarks([...bookmarks]);
                              }}
                            />
                          </>}

                          {/* TYPE NOTE */}
                          {(bookmark.bookmarkType && bookmark.bookmarkType === "note") && <>
                            <i className="fas fa-comment-alt"></i>
                            <Separator size={5} />
                            <span dangerouslySetInnerHTML={{ __html: truncateString(detectUrlInString(bookmark.note), 300, true) }}></span>
                          </>}

                        </div>
                        <div className="col-3 text-end">

                          {currentRole === "admin" && <>
                            <BookmarkRemove
                              teamId={teamId}
                              bookmarkId={bookmark.id}
                              className="me-2 display-on-hover-item remove-bookmark"
                              onSuccess={() => {
                                team.bookmarksCount = team.bookmarksCount - 1;
                                const index: number = bookmarks.findIndex((b: any) => b.id === bookmark.id);
                                bookmarks.splice(index, 1);
                                setBookmarks([...bookmarks]);
                                setAlert({ type: "success", message: "Bookmark removed" });
                              }}
                              onError={() => {
                                setAlert({ type: "danger", message: "Error removing bookmark" });
                                _listBookmarks();
                              }}
                            />
                          </>}

                          <Link to={`/teams/${teamId}/${bookmark.id}`} className="btn btn-link no-underscore">
                            <i className="fas fa-stream"></i> Thread
                          </Link>
                        </div>
                      </div>

                      <Separator size={10} />

                      {/* TYPE LINK */}
                      {(!bookmark.bookmarkType || bookmark.bookmarkType === "link") && <>

                        <BookmarkDescription
                          teamId={teamId}
                          bookmark={bookmark}
                          bookmarks={bookmarks}
                          currentRole={currentRole}
                          onSuccess={(e: any) => setBookmarks([...e.bookmarks])}
                          onError={(e: any) => setBookmarks([...e.bookmarks])}
                          />
                      </>}

                      {currentRole === "viewer" && <>
                        {(!bookmark.tags || bookmark.tags.length === 0) && <div className="badge">No Tags</div>}

                        {bookmark.tags.length > 0 && bookmark.tags.map((tag: any) => {
                          return <div key={`Bookmarks-bookmark-tag-item-${tag.id}`} className="badge">
                            {capitalizeString(tag.name)}
                          </div>
                        })}
                      </>}

                      <BookmarkTags
                        currentRole={currentRole}
                        teamId={teamId}
                        bookmark={bookmark}
                        tagsAvailable={data.teamTags}
                      />

                      <Separator size={20} />

                      <div className="row align-items-center">
                        <div className="col">
                          <small className="text-muted">{bookmark.totalComments || 0} Comments in thread</small>
                        </div>
                        <div className="col text-center">
                          <small className="text-muted"><i className="fas fa-chart-bar me-2"></i> {bookmark.totalClicks || 0} Clicks</small>
                        </div>
                        <div className="col text-end">

                          <Acronyms
                            position="right"
                            displayName={dayjs(convertFirebaseTimestampToString(bookmark.createdAt)).fromNow()}
                            users={[
                              {
                                name: bookmark.author.name,
                                color: bookmark.author.color,
                              },
                            ]} />

                        </div>
                      </div>

                      {bookmark.totalComments > 0 && bookmark.lastComment && <>
                        <Separator size={10} />

                        <MessageBalloon
                          placement="home"
                          comment={bookmark.lastComment}
                          truncateComment={220} />
                      </>}

                    </div>

                  </div>
                </div>{/* /.row */}

              </div>
            </li>
          })}
        </ul>


        {/* Load More section */}

        {loadingMoreBookmarks && <Loading loading={loadingBookmarks} />}

        {lastVisibleDocId && !data.filterByTag && <>
          <Separator size={40} />
          <div className="text-center">
            <small className="text-muted">
              {bookmarks.length || 0} of {team.bookmarksCount || 0} Bookmarks loaded
            </small>
            <Separator size={40} />
            <button
              onClick={() => _loadMoreBookmarks()}
              disabled={loadingBookmarks || loadingMoreBookmarks}
              className="btn btn-link btn-sm no-underscore">
                <Loading loading={loadingMoreBookmarks} parent="inline" color="text-primary" />
                {!loadingMoreBookmarks && <i className="fas fa-sync me-2"></i>} Load more
            </button>
          </div>
        </>}

      </>}
    </div>
  );
};

export default Bookmarks;