import ContentLoader from "react-content-loader";

export const SkeletonTeams = () => {
  return <ContentLoader 
    speed={2}
    width="100%"
    height={160}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{ width: "100%", height: "160px" }}
  >
    <rect x="0" y="0" rx="3" ry="3" width="calc((100% - 20px) / 3)" height="80" /> 
    <rect x="calc((100% - 20px) / 3 + 10px)" y="0" rx="0" ry="0" width="calc((100% - 20px) / 3)" height="80" /> 
    <rect x="calc(2 * (100% - 20px) / 3 + 20px)" y="0" rx="0" ry="0" width="calc((100% - 20px) / 3)" height="80" />

    <rect x="0" y="90" rx="3" ry="3" width="calc((100% - 20px) / 3)" height="80" /> 
    <rect x="calc((100% - 20px) / 3 + 10px)" y="90" rx="0" ry="0" width="calc((100% - 20px) / 3)" height="80" /> 
    <rect x="calc(2 * (100% - 20px) / 3 + 20px)" y="90" rx="0" ry="0" width="calc((100% - 20px) / 3)" height="80" />
  </ContentLoader>
};

export const SkeletonFeed = () => {
  return <ContentLoader 
    speed={2}
    width="100%"
    height={150}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{ width: "100%", height: "150px" }}
  >
    <circle cx="10" cy="20" r="8" /> 
    <rect x="25" y="15" rx="5" ry="5" width="360" height="10" /> 
    <circle cx="10" cy="50" r="8" /> 
    <rect x="25" y="45" rx="5" ry="5" width="300" height="10" /> 
    <circle cx="10" cy="80" r="8" /> 
    <rect x="25" y="75" rx="5" ry="5" width="320" height="10" /> 
    <circle cx="10" cy="110" r="8" /> 
    <rect x="25" y="105" rx="5" ry="5" width="280" height="10" />
  </ContentLoader>
};
