import {
  Separator,
  convertFirebaseTimestampToString,
  detectUrlInString,
  truncateString,
} from "@moreirapontocom/npmhelpers";

import "./MessageBalloon.scss";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const MessageBalloon = (props: any) => {
  const {
    comment,
    placement,
    truncateComment,
  }: any = props;

  let commentContent: string = truncateComment && typeof truncateComment === "number" ? detectUrlInString(truncateString(comment.comment, truncateComment, true)) : detectUrlInString(comment.comment);

  // Replace [] (this is a mention) with <strong> tags
  commentContent = commentContent.replace(/\[([^\]]+)\]/g, "<strong class='text-primary'>[$1]</strong>");

  return (
    <div className={`comment ${(placement === "home") && "mb-0"}`}>
      <small className="me-1">
        <strong>{comment.author.name}</strong>
      </small>
      <span className="text-muted messageTimestamp">
        - {dayjs(convertFirebaseTimestampToString(comment.createdAt)).fromNow()}
      </span>

      <Separator size={1} />

      <span className="line-break break-content" dangerouslySetInnerHTML={{ __html: commentContent }}></span>
    </div>
  );
};

export default MessageBalloon;