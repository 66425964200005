import axios from "axios";
import CreateBookmark from "../interfaces/createBookmark.interface";
import UpdateBookmarkField from "../interfaces/updateBookmarkField.interface";

export const listBookmarks = async (teamId: string, tag: any, lastDocId?: string) => await axios.post(`/bookmarks/${teamId}/filter`, {tag, lastDocId}).then((res) => res.data).catch((err) => err);

export const getBookmark = async (teamId: string, bookmarkId: string) => await axios.get(`/bookmarks/${teamId}/${bookmarkId}`).then((res) => res.data).catch((err) => err);

export const incrementVisitBookmark = async (teamId: string, bookmarkId: string) => await axios.post(`/bookmarks/${teamId}/${bookmarkId}/incrementVisit`).then((res) => res.data).catch((err) => err);

export const createBookmark = async (teamId: string, payload: CreateBookmark) => await axios.post(`/bookmarks/${teamId}`, payload).then((res) => res.data).catch((err) => err);

export const updateBookmarkField = async (teamId: string, bookmarkId: string, payload: UpdateBookmarkField) => await axios.post(`/bookmarks/${teamId}/${bookmarkId}/updateField`, payload).then((res) => res.data).catch((err) => err);

export const removeBookmark = async (teamId: string, bookmarkId: string) => await axios.delete(`/bookmarks/${teamId}/${bookmarkId}`).then((res) => res.data).catch((err) => err);
