import { useContext } from 'react';
import { updateTags } from '../../services/tags.service';
import { AlertContext } from '@moreirapontocom/npmhelpers';
import { capitalizeString } from '../../services/helpers.service';
import { DataContext } from '../../context/Data.context';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import * as _ from "lodash";

const BookmarkTags = (props: any) => {
  const { setAlert } = useContext(AlertContext);
  const { data, setData } = useContext(DataContext);
  const {
    currentRole,
    teamId,
    bookmark,
    tagsAvailable,
  } = props;

  const _updateTags = async (tags: any) => {
    const uniqueTags: any = _.uniqBy(tags, 'id');
    bookmark.tags = uniqueTags;
    const response: any = await updateTags(teamId, bookmark.id, uniqueTags).then((res: any) => res).catch((err: any) => err);
    if (response.message === "OK") {
      setData({ ...data, newTag: true });
      setAlert({ message: "Tags updated", type: "success" });
      return;
    }

    console.log("Error updating tags:", response);
  };

  const _getCapitalizedList = (list: any) => {
    return list.map((item: any) => {
      return { ...item, name: capitalizeString(item.name) };
    });
  }

  return <>
    {["admin", "member"].includes(currentRole) && <>
      <Typeahead
        id="basic-typeahead-multiple"
        labelKey="name"
        multiple
        caseSensitive={false}
        allowNew
        onChange={(selectedTag: any) => _updateTags(selectedTag)}
        options={tagsAvailable || []}
        placeholder="Click to add tags"
        selected={bookmark.tags ? _getCapitalizedList(bookmark.tags) : []}
      />
    </>}
  </>
};

export default BookmarkTags;