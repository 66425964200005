import { createContext, useEffect, useState } from "react";

const emptyData: any = {
    user: {
        uid: null,
        displayName: null,
        email: null,
        accessToken: null,
        color: null,
    },
    teams: [
        {
            id: null,
            name: null,
            membersCount: null,
            bookmarksCount: null,
        }
    ],
    currentTeam: {
        id: null,
        name: null,
        membersCount: null,
        bookmarksCount: null,
    },
    teamTags: [],
    filterByTag: null,
    newTag: null,
};

export const DataContext = createContext(emptyData as any);

export const DataProvider = ({ children }: any) => {
    const [data, setData] = useState(emptyData as any);

    useEffect(() => {
        if (data.currentTeam.id) {
            localStorage.setItem("data", JSON.stringify(data))
        }
    }, [data]);

    return <DataContext.Provider value={{data, setData}}>{children}</DataContext.Provider>
};