import { Separator } from "@moreirapontocom/npmhelpers";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/Data.context";

const Profile = (props: any) => {
  const {data} = useContext(DataContext);
  const [user, setUser] = useState(null as any);

  useEffect(() => {
    setUser(data.user);
  }, []);

  return <>
    <div className="Feed container bg-white p-4 shadow-sm rounded-3">
      <small className="text-muted"><i className="fas fa-id-badge me-2"></i> Your Profile</small>

      <Separator size={30} />

      <small className="text-muted">
        Manage your personal information and account settings.<br />
        This feature is comming soon.<br />
        We will notify you when it's ready.
      </small>

      {user && <>
        <Separator size={30} />

        <strong>{user.displayName}</strong> ({user.email})
      </>}

    </div>
  </>
};

export default Profile;